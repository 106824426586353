import * as React from 'react';
import cn from 'classnames';
import {
  type AllProps,
  type IViewProps,
  type IViewState,
  View,
} from '~/js/core/base/view/View';

import ProcessMath from '../../utils/mathjax/ProcessMath';

import '~/css/blocks/richtext.less';
import '~/css/blocks/fabdesk.less';
import '~/css/blocks/generic/btn.less';

namespace RichTextView {
  export interface Props extends IViewProps {
    content: string;
  }

  export interface State extends IViewState {
    isScrollUpVisible: boolean;
    isScrollDownVisible: boolean;
    hasTextOverflow: boolean;
  }

  export class RichTextView extends View<Props, State> {
    private readonly richtextRef: React.RefObject<HTMLDivElement> =
      React.createRef();

    /** provides an event hook for content root size changes */
    private readonly resizeObserver: ResizeObserver = new ResizeObserver(
      this.handleViewResize,
    );

    constructor(props: AllProps<Props>) {
      super(props);

      this.state = {
        isScrollUpVisible: false,
        isScrollDownVisible: false,
        hasTextOverflow: true,
      };

      this.scrollUp = this.scrollUp.bind(this);
      this.scrollDown = this.scrollDown.bind(this);

      this.resizeObserver = new ResizeObserver(
        this.handleViewResize.bind(this),
      );
    }

    componentDidMount() {
      super.componentDidMount();

      const rt = this.richtextRef.current;

      rt?.addEventListener('scroll', this.handleContentScroll.bind(this));

      if (rt) {
        this.resizeObserver.observe(rt);
      }
    }

    componentWillUnmount() {
      super.componentWillUnmount();

      this.resizeObserver.disconnect();
    }

    componentDidUpdate(
      prevProps: Readonly<AllProps<RichTextView.Props>>,
      prevState: Readonly<RichTextView.State>,
      snapshot?: any,
    ) {
      // handle content changes
      if (prevProps.content !== this.props.content) {
        // the height of the content may change, so update the overflow state
        this.checkTextOverflow();
        // reset the scroll state
        this.handleContentScroll();
        // auto-scroll the content root to the top
        this.scrollUp();
      }
    }

    /**
     * Handles content root size changes
     */
    handleViewResize() {
      // the height of the content may change, so update the overflow state
      this.checkTextOverflow();
      // reset the scroll state
      this.handleContentScroll();
    }

    /**
     * Toggles overflow state for the view
     *
     * If the content overflows, the state is enabled
     */
    checkTextOverflow() {
      const scrollHeight = this.richtextRef.current?.scrollHeight;
      const clientHeight = this.richtextRef.current?.clientHeight;

      if (scrollHeight == null || clientHeight == null) {
        return false;
      }

      this.setState((state) => ({
        ...state,
        hasTextOverflow: clientHeight < scrollHeight,
      }));
    }

    /**
     * Handles scroll event on the content root
     */
    handleContentScroll() {
      const scrollHeight = this.richtextRef.current?.scrollHeight;
      const clientHeight = this.richtextRef.current?.clientHeight;
      const scrollCurrent = this.richtextRef.current?.scrollTop;

      if (
        scrollHeight == null ||
        clientHeight == null ||
        scrollCurrent == null
      ) {
        return;
      }

      if (scrollCurrent === 0) {
        this.setState((state) => ({
          ...state,
          isScrollDownVisible: true,
          isScrollUpVisible: false,
        }));
      } else {
        this.setState((state) => ({
          ...state,
          isScrollDownVisible: false,
          isScrollUpVisible: true,
        }));
      }
    }

    /**
     * Automatically scrolls up the content root to the top
     */
    scrollUp() {
      this.richtextRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }

    /**
     * Automatically scrolls down the content root to the bottom
     */
    scrollDown() {
      const scrollHeight = this.richtextRef.current?.scrollHeight;

      this.richtextRef.current?.scrollTo({
        top: scrollHeight,
        behavior: 'smooth',
      });
    }

    render(): React.ReactNode {
      const hasOverflow = this.state.hasTextOverflow;

      return (
        <div
          className={cn({
            richtext: true,
            richtext_ellipsis: hasOverflow,
          })}
        >
          <div className="richtext__scrollview" ref={this.richtextRef}>
            <ProcessMath>
              <div
                className={cn({
                  richtext__content: true,
                  richtext__content_overflow: hasOverflow,
                })}
                dangerouslySetInnerHTML={{ __html: this.props.content }}
              />
            </ProcessMath>
          </div>
          {hasOverflow && (
            <div className="fabdesk fabdesk_vertical">
              {this.state.isScrollUpVisible && (
                <div
                  className="fabdesk__fab btn btn_primary btn_circular"
                  onClick={this.scrollUp}
                >
                  <i className="fa fa-angle-double-up"></i>
                </div>
              )}
              {this.state.isScrollDownVisible && (
                <div
                  className="fabdesk__fab btn btn_primary btn_circular"
                  onClick={this.scrollDown}
                >
                  <i className="fa fa-angle-double-down"></i>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
  }
}

export default RichTextView;
